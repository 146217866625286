/************ Custom Font ************/
@font-face {
	font-family: Playfair Display;
	src: url('./assets/font/PlayfairDisplay-VariableFont_wght.ttf');
}
@font-face {
	font-family: Playfair Display Italic;
	src: url('./assets/font/PlayfairDisplay-Italic-VariableFont_wght.ttf');
}

/************ Default CSS ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background: linear-gradient(180deg, #5F4845 -3%, #f0d1d4 25.58%);
  color: #FFFFFF !important;
  font-family: 'Playfair Display', serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

hr.divider {
  max-width: 80%;
  margin: 0px auto 80px auto;
  color: #5F4845;
  opacity: 1;
}

hr.contact-divider {
  max-width: 74.0%;
  margin: 0px auto 60px auto;
  color: #5F4845;
  opacity: 1;
}


/************ Navbar CSS ************/
nav.navbar {
  padding: 18px 0 50px 0;
  position: fixed;
  width: 100%;
  top: 0;
  transition:  0.32 ease-in-out;
  z-index: 9999;
}
nav.navbar.scrolled {
  padding: 10px 0 20px 0;
  background: linear-gradient(180deg, #5F4845 -5.91%, transparent 100.98%);
}
nav.navbar a.navbar-brand {
    margin: 0 16px 3px 16px;
    display: inline-block;
    font-size: 30px;
    font-weight: 800;
    width: 12%;
    color: #fff !important;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 1;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
    color: #F0D1D4 !important;
    font-weight: 600;
}

span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: #5F4845;
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 2px solid #5F4845;
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #FFFFFF;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    background-color: #5F4845;
    border: 2px solid #5F4845;
}
.social-icon a img {
	width: 100%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  transition-duration: 150ms;
  transform: scale(1.2);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #5F4845;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner CSS ************/
.banner {
  margin-top: 0;
  padding: 260px 0 40px 0;
  position: relative;
}
.banner .tagline {
  color: #5F4845;
  font-weight: 800;
  letter-spacing: 0.8px;
  padding: 0px 0px;
  font-size: 40px;
  margin-bottom: 5px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 10px;
  display: block;
}
.banner p {
  color: #5F4845;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 8px 0 10px 0;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 20px;
  letter-spacing: 0.8px;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.wrap {
  color: #F0D1D4;
  margin: 0px;
  display: inline-block;
  height: 100%;
  font-weight: 500;
}

/************ Skills CSS ************/
.skill {
  padding: 60px 0;
  position: relative;
}
.skill-bx {
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h2 {
  color: #5F4845;
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.skill p {
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}
.item-bx {
  padding: 12px;
}
.item {
  width: 100%;
  margin: auto;
  text-align: center;
}
.item h5{
  margin: 5px auto;
  color: #5F4845;
}
.item svg{
  color: #A17F7D;
  height: 100%
}
.item svg:hover {
  transition-duration: 150ms;
  transform: scale(1.1);
  color: #5F4845
}
.item img{
  color: #A17F7D;
  width: 5em;
}
.item.matplotlib img:hover {
  transition-duration: 150ms;
  transform: scale(1.1);
  content: url(assets/img/Matplotlib_icon_hover.svg);
  fill: #5F4845;  
}
.item.seaborn img:hover {
  transition-duration: 150ms;
  transform: scale(1.1);
  content: url(assets/img/seaborn_hover.svg);
  fill: #5F4845;  
}

/************ Projects CSS ************/
.project {
  padding: 50px 0;
  position: relative;
  background-image: url('./assets/img/footer.png'), linear-gradient(180deg, #F0D1D4 30%, #d7ded1 70.9%);
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
}
.project-bx {
  text-align: center;
  padding: 50px 50px;
  margin-top: -60px;
}
.project h2 {
  color: #5F4845;
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  background: #5F4845;
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txt {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 90%;
  color: white;
}
.proj-imgbx:hover .proj-txt {
  top: 50%;
  opacity: 1;
}
.proj-txt h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txt span {
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txt div {
  margin-top: 1em;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Contact CSS ************/
.contact {
  padding: 80px 0 80px 0;
}
.contact-img {
  padding: 20px 0;
}
.contact img {
  width: 92%;
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.contact h2 {
  color: #5F4845;
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: #f0d1d47c !important;
  border: 1px solid #5f4845;
  border-radius: 20px;
  color: #5f4845 !important; 
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: #F0D1D4 !important;
  color: #5F4845 !important;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
	font-weight: 700;
  color: #5f4845 !important;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #5f4845 !important;
  background-color: #f0d1d4c2 !important;
  opacity: 1;
}
.contact form button {
  font-weight: 700;
  color: #5f4845;
  background-color: #f0d1d4c2 !important;
  border: 1px solid #5f4845;
  border-radius: 20px;
  padding: 14px;
  height: 100%;
  width: 100%;
  font-size: 20px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #f0d1d4;
  border: 1px solid #5f4845 !important;

}
.contact form button::before {
  content: "";
  background: #5f4845 !important;
  border-radius: 20px;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  color: #f0d1d4 !important;
  width: 100%;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 100px #f0d1d4 inset !important;
    -webkit-text-fill-color: #5f4845 !important;
}


/************ SuccessMsg CSS ************/
.success {
  padding: 110px 0 80px 0;
}
.success h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.7em;
  color: #5F4845;
  text-align: center;
  margin-top: 30px;
}
.success img {
  max-width: 40%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}

/************ Footer CSS ************/
.footer {
  padding: 30px 0;
}
.footer a {
  text-decoration: none;
}
.footer h1 {
  color: #5f4845 !important;
  font-weight: 700;
  margin: 0;
  padding: auto;
  text-align: right;
}
.footer-bx {
  background: #f0d1d4c2!important;
  border: 1px solid #5f4845;
  border-radius: 20px;
  margin: 0;
  padding: 18px 26px;
}
.footer img {
  width: 26%;
}
.footer p {
  font-weight: 700;
  font-size: 20px;
  color: #5f4845;
  letter-spacing: 0.9px;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}