  /* .javascriptLogo,
  .djangoLogo,
  .pythonLogo,
  .bootstrapLogo,
  .reactLogo {
    position: absolute;
    height: 40px;
  }
  
  .javascriptLogo {
    height: 50px;
  }
  
  .djangoLogo {
    height: 25px;
  } */
  
  .javascriptLogo {
    animation: orbit1 5s infinite linear;
  }
  
  .pythonLogo {
    animation: orbit2 5s infinite linear;
  }
  
  .bootstrapLogo {
    animation: orbit3 5s infinite linear;
  }
  
  .reactLogo {
    animation: orbit4 5s infinite linear;
  }
  
  .djangoLogo {
    animation: orbit5 5s infinite linear;
  }
  
  .imgContainer {
    display: inline-block;
    margin-top: 70px;
  }
  
  @keyframes orbit1 {
    from {
      transform: rotate(0deg) translateX(70px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(70px) rotate(-360deg);
    }
  }
  
  @keyframes orbit2 {
    from {
      transform: rotate(72deg) translateX(70px) rotate(-11deg);
    }
    to {
      transform: rotate(432deg) translateX(70px) rotate(-371deg);
    }
  }
  
  @keyframes orbit3 {
    from {
      transform: rotate(144deg) translateX(70px) rotate(-140deg);
    }
    to {
      transform: rotate(504deg) translateX(70px) rotate(-500deg);
    }
  }
  
  @keyframes orbit4 {
    from {
      transform: rotate(216deg) translateX(70px) rotate(-6deg);
    }
    to {
      transform: rotate(576deg) translateX(70px) rotate(-366deg);
    }
  }
  
  @keyframes orbit5 {
    from {
      transform: rotate(280deg) translateX(70px) rotate(84deg);
    }
    to {
      transform: rotate(640deg) translateX(70px) rotate(-276deg);
    }
  }